import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Landing from './pages/landing';
import Brands from './pages/brands';
import About from './pages/about';
import AffiliatedCompany from './pages/affiliatedCompany';
import Layout from './layout';
import { Helmet } from 'react-helmet';
import Services from './pages/services';
import ContactUs from './pages/contact-us';
// import News from './pages/news';
// import SingleNews from './pages/singleNews';
import WhySaja from './pages/whySaja';

function App() {
	return (
		<>
			<Helmet>
				<title>SAJA | Education Services</title>
			</Helmet>
			<BrowserRouter>
				<Layout>
					<Routes>
						<Route path='/' element={<Landing />} />
						<Route path='/our-brands' element={<Brands />} />
						<Route path='/affiliated-company' element={<AffiliatedCompany />} />
						<Route path='/about' element={<About />} />
						<Route path='/our-services' element={<Services />} />
						<Route path='/contact-us' element={<ContactUs />} />
						{/* <Route path='/news' element={<News />} />
						<Route path='/news/singlenews/:id' element={<SingleNews />} /> */}
						<Route path='/why-saja' element={<WhySaja />} />
					</Routes>
				</Layout>
			</BrowserRouter>
		</>
	);
}

export default App;
