import styles from './index.module.scss';
import cardOne from '../../assets/icons/approach-card-one.svg';
import cardTwo from '../../assets/icons/approach-card-two.svg';
import cardThree from '../../assets/icons/approach-card-three.svg';
import cardFour from '../../assets/icons/approach-card-four.svg';
// import bgImage from '../../assets/images/water-mark.webp';

export default function Approach() {
  return (
    <section className={styles['approach']}>
      <p className={styles['approach-hint']}>Services</p>
      <h2 className={styles['approach-title']}>Aiming to nurture every aspect of a child's growth</h2>
      <p className={styles['approach-desc']}>Saja approach goes beyond traditional teaching methods by focusing on the complete development of a child—cognitively, emotionally, socially, and physically.</p>
      <div className={styles['approach-cards']}>
        <div className={styles['approach-card']}>
          <div className={styles['approach-card-image']}>
            <img src={cardOne} alt='' />
          </div>
          <h3 className={styles['approach-card-title']}>Whole-Child Development</h3>
          <p className={styles['approach-card-desc']}>Nurture every aspect of a child's growth.</p>
        </div>
        <div className={styles['approach-card']}>
          <div className={styles['approach-card-image']}>
            <img src={cardTwo} alt='' />
          </div>
          <h3 className={styles['approach-card-title']}>Integrated Learning Experiences</h3>
          <p className={styles['approach-card-desc']}>Stimulate different areas of a child's development.</p>
        </div>
        <div className={styles['approach-card']}>
          <div className={styles['approach-card-image']}>
            <img src={cardThree} alt='' />
          </div>
          <h3 className={styles['approach-card-title']}>Personalized Education</h3>
          <p className={styles['approach-card-desc']}>Recognizing thot each child is unique.</p>
        </div>
        <div className={styles['approach-card']}>
          <div className={styles['approach-card-image']}>
            <img src={cardFour} alt='' />
          </div>
          <h3 className={styles['approach-card-title']}>Emotional and Social Growth</h3>
          <p className={styles['approach-card-desc']}>Emphasis on emotional intelligence & social skills.</p>
        </div>
      </div>
      {/* <img src={bgImage} className={styles['bg-image']} alt='' /> */}
    </section>
  )
}
